import memoize from 'lodash.memoize';

const memoizeKey = (...args) => JSON.stringify(args);

export const withFetchJson = cb => (...args) => fetch(...cb(...args)).then(res => res.json());

export const withHost = cb => {
  return (host, ...extra) => {
    if (!host) throw Error('host required');
    const [path, options = {}] = cb(...extra);
    const url = `${host.replace(/\/+$/, '')}${path}`;
    return [url, options];
  };
};

export const withAuth = cb => {
  return (auth, ...extra) => {
    if (!auth) throw Error('auth required');
    const [path, options = {}] = cb(...extra);
    return [
      path,
      {
        ...options,
        headers: {
          Authorization: JSON.stringify(auth),
          ...options.headers
        }
      }
    ];
  };
};

export const withJsonBody = cb => {
  return (...extra) => {
    const [path, options = {}] = cb(...extra);
    return [
      path,
      {
        method: 'POST',
        ...options,
        body: JSON.stringify(options.body),
        headers: {
          'Content-type': 'application/json',
          ...options.headers
        }
      }
    ];
  };
};

export const toQuery = (params = []) =>
  (Array.isArray(params) ? params : Object.entries(params))
    .map(([key, val]) => [key, encodeURIComponent(val)].join('='))
    .join('&');

export const toProductId = product =>
  JSON.stringify(
    []
      .concat(product)
      .map(it => (typeof it === 'object' ? it.id : it))
      .filter(it => it)
  );

export const fetchOffer = memoize(
  withFetchJson(
    withHost((merchantId, sessionId, product, module = 'pdp') => {
      if (!merchantId) throw Error('merchantId required');
      if (!sessionId) throw Error('sessionId required');
      if (!product) throw Error('product required');

      const query = toQuery([
        ['session_id', sessionId],
        ['page_type', 1],
        ['p', toProductId(product)],
        ['module_view', JSON.stringify(['regular'])]
      ]);

      return [`/offer/${merchantId}/${module}?${query}`];
    })
  ),
  memoizeKey
);

export const fetchOrders = memoize(
  withFetchJson(
    withHost(
      withAuth((status = 1, ordering = 'place') => [
        `/orders/?${toQuery([
          ['status', status],
          ['ordering', ordering],
          ['exclude_prepaid_orders', 'true']
        ])}`
      ])
    )
  ),
  memoizeKey
);

export const fetchItems = memoize(
  withFetchJson(
    withHost(
      withAuth(orderId => {
        if (!orderId) throw Error('orderId required');
        return [`/items/?order=${orderId}`];
      })
    )
  ),
  memoizeKey
);

export const createOneTime = withFetchJson(
  withHost(
    withAuth(
      withJsonBody((product, order, quantity, offer) => {
        if (!product) throw Error('product required');
        if (!order) throw Error('order required');
        if (!quantity) throw Error('quantity required');
        if (quantity <= 0) throw Error('quantity must be greater or equal than one');
        if (!offer) throw Error('offer required');
        return ['/items/iu/', { body: { product, order, quantity, offer } }];
      })
    )
  )
);

export const parseFrequency = raw => {
  if (typeof raw === 'object') {
    return { ...raw };
  }

  const [every, every_period] = (raw || '').split(/_/).map(n => parseInt(n, 10));
  return every && every_period && { every, every_period };
};

export const isFrequencyValid = it => it.match(/^\d+_\d$/);
export const compareFrequencies = (a, b) =>
  String.prototype.localeCompare.call(
    a &&
      a
        .split('_')
        .reverse()
        .join('_'),
    b &&
      b
        .split('_')
        .reverse()
        .join('_')
  );

export const parseFrequenciesList = value =>
  [...new Set(value && value.split(/\s+/))].filter(isFrequencyValid).sort(compareFrequencies);

export const stringifyFrequencies = value => (value == null ? value : value.join(' '));

export const stringifyFrequency = ref => {
  if (typeof ref === 'object') {
    const { every, period, every_period } = ref;
    return `${every}_${period || every_period}`;
  }
  if (typeof ref === 'string') return ref;
  return '';
};

export const convertOneTimeToSubscription = withFetchJson(
  withHost(
    withAuth(
      withJsonBody((item, frequency, offer) => {
        if (!item) throw Error('item required');
        if (!frequency) throw Error('frequency required');
        const parsedFrequency = parseFrequency(frequency);
        if (!parsedFrequency) throw Error('invalid frequency');

        return ['/subscriptions/create_from_item/', { body: { item: item.public_id, offer, ...parsedFrequency } }];
      })
    )
  )
);

export const api = { fetchOffer, fetchOrders, fetchItems, createOneTime, convertOneTimeToSubscription };

export default api;
