import { TestWizard } from './components/TestWizard';

export default function() {
  const name = 'og-test-wizard';
  if (!customElements.get(name)) {
    customElements.define(name, TestWizard);
  }
  const modal = document.createElement(name);
  document.body.appendChild(modal);
}
